import { InjectionKey } from 'vue';
import { Emitter } from 'mitt';
import { IPortfolioTreeSubportfolio, IPortfolioTreeStrategy } from './IPortfolioTree';
import { ISidebarRow } from './ISidebarRow';
import { GlobalEventTypes } from '@/composables/useGlobalEventBus';
import { IPcaOption } from '@/api-v2/web/pca';

export enum PortfolioControllerEvents {
  ANALYTICS_CALCULATE = 'analytics:calculate',
  NAVIGATE_TO_CELL = 'navigate-to-cell',
  UPDATE_CHILDREN = 'update-children',
  FOCUS = 'focus',
  RESCALE_DOWN = 'RESCALE-DOWN',
  RESCALE_UP = 'RESCALE-UP',
  OPEN_SUBPORTFOLIO = 'open-subportfolio',
  TOGGLE_PIN = 'toggle-pin',
  CLEAR_PINNED = 'clear-pinned',
  COLLAPSE_EVENT = 'collapse-event',
  ON_NAME_CLICK = 'on-name-click',
  CLEAR_FORCE_ITEM_UNDER_ANALYSIS = 'clear-force-item-under-analysis',
  EXPAND_NAVIGATION_PANEL = 'expand-navigation-panel',
  SET_PORTFOLIO_TREE_HAS_CHANGES = 'SET-PORTFOLIO-TREE-HAS-CHANGES',
  ADD_SUBPORTFOLIO_TO_TREE = 'ADD-SUBPORTFOLIO-TO-TREE',
  UPDATE_CASH = 'UPDATE-CASH',
  RESCALE = 'RESCALE',
}

export enum ClusteringAnalysisEvents {
  CALCULATE = 'pca:calculate',
}
export type ClusteringAnalysisCalculateEvent = Record<ClusteringAnalysisEvents.CALCULATE, IPcaOption>;

export enum ModalEvents {
  OPEN_MODAL = 'OPEN-MODAL',
}

export enum StrategyFactsheetEvents {
  SAVE_ATTRIBUTES = 'factsheet:save-attributes',
  CLEAR_ATTRIBUTES = 'factsheet:clear-attributes',
  CANCEL_EDIT_ATTRIBUTES = 'factsheet:cancel-edit-attributes',
}

export type StrategyFactsheetParameter = Record<StrategyFactsheetEvents, void>;

export type AnalyticsCalculateEvent = Record<PortfolioControllerEvents.ANALYTICS_CALCULATE, void>;
export type NavigateToCellEvent = Record<
  PortfolioControllerEvents.NAVIGATE_TO_CELL,
  { treeId: string; direction?: 'up' | 'down' }
>;
export type TreeIdParameter = Record<
  PortfolioControllerEvents.FOCUS | PortfolioControllerEvents.OPEN_SUBPORTFOLIO | PortfolioControllerEvents.RESCALE_UP,
  { treeId: string }
>;
export type UpdateRowChildrenEvent = Record<
  PortfolioControllerEvents.UPDATE_CHILDREN,
  { parentId: string; newChildren: ISidebarRow[] }
>;
export type RescaleDownParameter = Record<
  PortfolioControllerEvents.RESCALE_DOWN,
  { parentTreeId: string; weightingMultiplier: number }
>;
export type TogglePinEvent = Record<
  PortfolioControllerEvents.TOGGLE_PIN,
  {
    id: string;
    row: ISidebarRow;
    data: IPortfolioTreeSubportfolio | IPortfolioTreeStrategy | undefined;
  }
>;
export type VoidParameter = Record<
  PortfolioControllerEvents.CLEAR_PINNED | PortfolioControllerEvents.COLLAPSE_EVENT,
  void
>;

export type OpenStaticDataModalEvent = Record<
  GlobalEventTypes.OPEN_STATIC_DATA_MODAL,
  {
    portfolioTreeId?: string;
    timerId?: number;
    shouldEdit?: boolean;
    isPointerPortfolio?: boolean;
    isNewSubportfolio?: boolean;
  }
>;

export type OpenInspectPerformanceContributionModalEvent = Record<
  GlobalEventTypes.OPEN_INSPECT_PERFORMANCE_ATTRIBUTION_MODAL,
  {
    portfolioId: number;
    code: string;
  }
>;

export type OpenInspectPerformanceContributionBackfillModalEvent = Record<
  GlobalEventTypes.OPEN_INSPECT_PERFORMANCE_ATTRIBUTION_BACKFILL_MODAL,
  {
    portfolioId: number;
    code: string;
  }
>;

export type OpenEditClientRequestModalEvent = Record<GlobalEventTypes.OPEN_EDIT_CLIENT_REQUEST_MODAL, { id: string }>;

export type OpenBatchEditClientRequestDetailProviderModalEvent = Record<
  GlobalEventTypes.OPEN_BATCH_EDIT_CLIENT_REQUEST_DETAIL_PROVIDER_MODAL,
  { constituentStatusIds: string[] }
>;

export type OpenBatchEditClientRequestDetailConstituentStatusModalEvent = Record<
  GlobalEventTypes.OPEN_BATCH_EDIT_CLIENT_REQUEST_DETAIL_CONSTITUENT_STATUS_MODAL,
  { constituentStatusIds: string[] }
>;

export type OpenBatchEditClientRequestDetailValidationStatusModalEvent = Record<
  GlobalEventTypes.OPEN_BATCH_EDIT_CLIENT_REQUEST_DETAIL_VALIDATION_STATUS_MODAL,
  { constituentStatusIds: string[] }
>;

export type OpenBatchEditClientRequestDetailReleaseStatusModalEvent = Record<
  GlobalEventTypes.OPEN_BATCH_EDIT_CLIENT_REQUEST_DETAIL_RELEASE_STATUS_MODAL,
  { id: string; clientRequestDetailIds: string[] }
>;

export type OpenEditPrivateTrackModalEvent = Record<
  GlobalEventTypes.OPEN_EDIT_PRIVATE_TRACK_MODAL,
  { trackIdList: string[] }
>;

export type OpenKnowledgeBaseMultiMediaModalEvent = Record<
  GlobalEventTypes.OPEN_KNOWLEDGE_BASE_MULTI_MEDIA_MODAL,
  { content: string }
>;

export type ModalParamValues = {
  id: string;
  shouldShowStock?: boolean;
  shouldRedirectToUniverseOnCancel?: boolean;
  shouldShowOnlyStrategies?: boolean;
};

export type ModalIdParameter = Record<ModalEvents.OPEN_MODAL, ModalParamValues>;

export type PortfolioControllerEventBusEvents =
  | NavigateToCellEvent
  | AnalyticsCalculateEvent
  | TreeIdParameter
  | UpdateRowChildrenEvent
  | TogglePinEvent
  | VoidParameter
  | ModalIdParameter;

const PortfolioControllerEventBusKey: InjectionKey<Emitter<PortfolioControllerEventBusEvents>> =
  Symbol('PortfolioControllerEventBus');

export default function (): {
  PortfolioControllerEventBusKey: InjectionKey<Emitter<PortfolioControllerEventBusEvents>>;
} {
  return {
    PortfolioControllerEventBusKey,
  };
}
