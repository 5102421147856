import request from '@/api-v2/request';
import { IDataDiscoveryTrackResponse } from './types/IDataDiscoveryTrackResponse';
import { IDataDiscoveryMstRequest } from './types/IDataDiscoveryMstRequest';
import { DataDiscoveryResponseDTO } from './types/DataDiscoveryResponseDTO';
import {
  DataDiscoveryMetricsRequestDTO,
  DataDiscoveryMetricsResponseDTO,
  DataDiscoveryVolScaledPortfolioMetricsRequestDTO,
  DataDiscoveryVolScaledStrategyMetricsRequestDTO,
} from './types/DataDiscoveryMetricsDTO';
import { PortfolioSummaryResponseDTO } from './types/PortfolioSummaryResponseDTO';
import { Period } from '@/types/period';
import { IMetric } from '@/types/strategy';
import { IMstResponse } from '@/types/IMstResponse';
import { DataDiscoveryTrackRequestDTO } from './types/DataDiscoveryTrackRequestDTO';
import {
  DataDiscoveryUniverseQuantSpaceRequestDTO,
  DataDiscoveryUniverseQuantSpaceResponseDTO,
} from './types/DataDiscoveryQuantSpaceDTO';

export const getDataUniverse = (): Promise<DataDiscoveryResponseDTO> =>
  request({
    url: `/discover`,
    method: 'get',
  }).then((o): DataDiscoveryResponseDTO => o.data);

export const getDataUniverseMetrics = (
  query: DataDiscoveryMetricsRequestDTO,
): Promise<DataDiscoveryMetricsResponseDTO> =>
  request({
    url: `/discover/metrics`,
    method: 'get',
    params: query,
  }).then((o): DataDiscoveryMetricsResponseDTO => o.data);

export const getQuantSpacePositions = (
  query: DataDiscoveryUniverseQuantSpaceRequestDTO,
): Promise<DataDiscoveryUniverseQuantSpaceResponseDTO> =>
  request({
    url: `/discover/universe-metrics`,
    method: 'get',
    params: query,
  }).then((o): DataDiscoveryUniverseQuantSpaceResponseDTO => o.data);

/**
 * Fetches metrics for a strategy for a given period
 * @param code Strategy code to fetch metrics for
 * @param volScaling Should volatility scaling be applied
 * @param volScalePeriod Period for which track needs to be Volatility scaled
 * @param analyticsPeriod Analytics period for which metrics needs to be calculated
 */
export const getStrategyMetrics = (
  query: DataDiscoveryVolScaledStrategyMetricsRequestDTO,
): Promise<{ result: IMetric }> => {
  return request({
    url: `/discover/strategy/${query.code}/metrics`,
    params: query,
    method: 'get',
  }).then((o) => o.data);
};

/**
 * Fetches metrics for a portfolio for a given period
 * @param slug Portfolio slug to fetch metrics for
 * @param volScaling Should volatility scaling be applied
 * @param volScalePeriod Period for which track needs to be Volatility scaled
 * @param analyticsPeriod Analytics period for which metrics needs to be calculated
 */
export const getPortfolioMetrics = (
  query: DataDiscoveryVolScaledPortfolioMetricsRequestDTO,
): Promise<{ result: IMetric }> => {
  return request({
    url: `/discover/portfolio/${query.slug}/metrics`,
    params: query,
    method: 'get',
  }).then((o) => o.data);
};

/**
 * Fetches track for a strategy for a given period with option of Volatility scaling
 * @param code Strategy code to fetch track for
 * @param volScaling Should volatility scaling be applied
 * @param period Period for which track needs to be fetched
 */
export const getStrategyTrack = (
  code: string,
  volScaling: boolean = false,
  period?: Period,
): Promise<IDataDiscoveryTrackResponse> =>
  request({
    url: `/discover/strategy/${code}/track`,
    params: {
      volScaling,
      startDate: period?.fromDateString(),
      endDate: period?.toDateString(),
      volScalingStartDate: period?.fromDateString(),
      volScalingEndDate: period?.toDateString(),
    },
    method: 'get',
  }).then((o) => o.data);

export const getStrategyTrackWithParams = (
  code: string,
  params: DataDiscoveryTrackRequestDTO,
): Promise<IDataDiscoveryTrackResponse> =>
  request({
    url: `/discover/strategy/${code}/track`,
    params,
    method: 'get',
  }).then((o) => o.data);

/**
 * Fetches track for a portfolio for a given period with option of Volatility scaling
 * @param slug Portfolio slug to fetch track for
 * @param volScaling Should volatility scaling be applied
 * @param period Period for which track needs to be fetched
 */
export const getPortfolioTrack = (
  slug: string,
  volScaling = false,
  period?: Period,
): Promise<IDataDiscoveryTrackResponse> =>
  request({
    url: `/discover/portfolio/${slug}/track`,
    params: {
      volScaling,
      startDate: period?.fromDateString(),
      endDate: period?.toDateString(),
      volScalingStartDate: period?.fromDateString(),
      volScalingEndDate: period?.toDateString(),
    },
    method: 'get',
  }).then((o) => o.data);

export const getPortfolioTrackWithParams = (
  code: string,
  params: DataDiscoveryTrackRequestDTO,
): Promise<IDataDiscoveryTrackResponse> =>
  request({
    url: `/discover/portfolio/${code}/track`,
    params,
    method: 'get',
  }).then((o) => o.data);

export const getMst = (data: IDataDiscoveryMstRequest): Promise<IMstResponse> =>
  request({
    url: `/discover/mst`,
    method: 'post',
    data,
  }).then((o) => o.data);

export const getPortfolioSummary = (): Promise<PortfolioSummaryResponseDTO[]> =>
  request({
    url: `/discover/portfolios/summary`,
    method: 'get',
  }).then((o) => o.data);
