import { computed, Ref, ref } from 'vue';
import usePortfolioTree from './usePortfolioTree';
import {
  IPortfolioTreeStrategy,
  IPortfolioTreeSubportfolio,
  isPortfolioTreeStrategy,
  isPortfolioTreeSubportfolio,
} from '@/types/IPortfolioTree';
import { useFxConvertedStrategyTracksByDate } from './useFxConvertedStrategyTracksByDate';
import { MeasurementTypeConstants } from '@/constants/MeasurementTypeConstants';
import { createGlobalState } from '@vueuse/shared';

const DEFAULT_BASKET_NOTIONAL = 1_000_000;

/**
 * Golden source of Measurement Type for the portfolio
 */
export const useParentDriverType = createGlobalState(() => {
  return ref<MeasurementTypeConstants>(MeasurementTypeConstants.WEIGHTS);
});

export function useTriangulation(
  portfolioTreeItem: Ref<IPortfolioTreeStrategy | IPortfolioTreeSubportfolio | null> | null = null,
) {
  const { masterPortfolioTree } = usePortfolioTree();

  const portfolioValue = computed(() => {
    return masterPortfolioTree.value?.portfolioTree.portfolioValue ?? DEFAULT_BASKET_NOTIONAL;
  });

  const portfolioCash = computed(() => {
    return masterPortfolioTree.value?.portfolioTree?.portfolioCash ?? 0;
  });

  const strategyCode = computed(() => {
    if (
      portfolioTreeItem &&
      portfolioTreeItem.value &&
      isPortfolioTreeStrategy(portfolioTreeItem.value) &&
      portfolioTreeItem.value.strategy
    ) {
      return portfolioTreeItem.value.strategy.code;
    }

    return null;
  });

  // TODO: Check if we want to include strategy/ stock nav in the portfolio tree.
  const { isPriceDataLoading, itemConvertedStrategyPrice, fxConvertedPriceData } =
    useFxConvertedStrategyTracksByDate(strategyCode);

  const itemWeighting = computed(() => {
    if (!portfolioTreeItem || !portfolioTreeItem.value) {
      return 0;
    }

    return portfolioTreeItem.value.weighting ?? 0;
  });

  /**
   * Calculated unit from triangulation maths
   * only applicable to strategies, for subportfolio it will be 0
   * It calculates the unit by (weighting * portfolio notional) / (strategy price * 100)
   * *100 is necessary as the weighting is multiplied by 100
   */
  const calculatedUnit = computed(() => {
    if (
      !portfolioTreeItem ||
      !portfolioTreeItem.value ||
      !isPortfolioTreeStrategy(portfolioTreeItem.value) ||
      !itemConvertedStrategyPrice.value
    ) {
      return 0;
    }

    return Number((itemWeighting.value * portfolioValue.value) / (itemConvertedStrategyPrice.value * 100));
  });

  const strategyUnit = computed(() => {
    if (portfolioTreeItem && portfolioTreeItem.value && isPortfolioTreeStrategy(portfolioTreeItem.value)) {
      return portfolioTreeItem.value.unit ?? 0;
    }

    return 0;
  });

  /**
   * Calculated weighting from triangulation maths
   * For subportfolio/ root level. It calculates the sum of all the weightings of the strategies in the subportfolio
   * For strategy, it calculates the weighting of the strategy by unit * price/ portfolio notional
   */
  const calculatedWeighting = computed(() => {
    if (!portfolioTreeItem || !portfolioTreeItem.value) return 0;

    if (isPortfolioTreeStrategy(portfolioTreeItem.value)) {
      if (portfolioValue.value && itemConvertedStrategyPrice.value) {
        return (itemConvertedStrategyPrice.value * strategyUnit.value * 100) / portfolioValue.value;
      }

      // TODO: derived portfolio value from unit and price if portfolio value is not available
      // Currently, we are returning 0 if portfolio value is not available
      return 0;
    }

    return getComponentWeighting(portfolioTreeItem.value);
  });

  const getComponentWeighting = (component: IPortfolioTreeSubportfolio) => {
    let totalWeighting = 0;
    for (const subComponent of component.components) {
      if (isPortfolioTreeStrategy(subComponent)) {
        totalWeighting += subComponent.weighting ?? 0;
      }

      if (isPortfolioTreeSubportfolio(subComponent)) {
        totalWeighting += getComponentWeighting(subComponent);
      }
    }

    return totalWeighting;
  };

  const getComponentStrategySum = (components: (IPortfolioTreeStrategy | IPortfolioTreeSubportfolio)[]) => {
    let sum = 0;
    for (const component of components) {
      if (isPortfolioTreeStrategy(component)) {
        const unit = component.unit ?? 0;
        const strategyCode = component.strategy?.code ?? component.reference ?? null;
        if (strategyCode && fxConvertedPriceData) {
          const fxConvertedPrice =
            fxConvertedPriceData.data.value?.find((x) => x.code === strategyCode)?.convertedTrack.value || 0;
          sum += fxConvertedPrice * unit;
        }
      } else {
        sum += getComponentStrategySum(component.components);
      }
    }

    return sum;
  };

  /**
   * Total equity notional
   */
  const calculatedTotalNotional = computed(() => {
    return getComponentStrategySum(masterPortfolioTree.value?.portfolioTree?.components || []);
  });

  /**
   * Calculated item notional
   * For subportfolio, it calculates the sum of notional of all the strategies/ equity in the subportfolio
   * For strategy, it calculates the unit * price * fx
   */
  const calculatedItemNotional = computed(() => {
    if (!portfolioTreeItem || !portfolioTreeItem.value) return 0;

    if (isPortfolioTreeStrategy(portfolioTreeItem.value)) {
      if (itemConvertedStrategyPrice.value) {
        return (portfolioTreeItem.value?.unit ?? 0) * itemConvertedStrategyPrice.value;
      }
      return 0;
    }

    return getComponentStrategySum([portfolioTreeItem.value]);
  });

  return {
    portfolioValue,
    DEFAULT_BASKET_NOTIONAL,
    portfolioCash,
    isPriceDataLoading,
    calculatedUnit,
    calculatedWeighting,
    calculatedTotalNotional,
    calculatedItemNotional,
  };
}
