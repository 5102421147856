<template>
  <div>
    <!-- The screen size component sets the Screen Size in settings module which plays a role in the responsiveness of a few pages
     we will eventually need to move away from responsiveness via the settings module, and use the composable instead
    -->
    <ScreenSizeComponent
      v-show="false"
      class="d-none d-md-block"
    />
    <b-sidebar
      id="bofa-application-sidebar"
      visible
      no-close-on-backdrop
      no-close-on-route-change
      no-close-on-esc
      no-header
      :aria-expanded="true"
      :width="currentApplicationSidebarWidth"
      bg-variant="light"
      body-class="border-right"
      z-index="1040"
    >
      <template #default>
        <div
          @mouseenter="isHovered = true"
          @mouseleave="isHovered = false"
        >
          <div class="d-flex justify-content-start">
            <div
              :style="{
                width: BofAApplicationSidebarWidth,
                paddingTop: BofASidebarPadding,
                paddingBottom: BofASidebarPadding,
                paddingLeft: BofASidebarLogoPaddingLeft,
              }"
            >
              <img
                src="@/assets/whitelabel/bofa/bofa-nova-star.svg"
                height="70"
              />
            </div>

            <div
              v-show="isHovered"
              :style="{
                width: `calc(${BofAExpandedSideBarWidth} - ${BofAApplicationSidebarWidth})`,
                paddingTop: BofASidebarPadding,
              }"
            >
              <img
                src="@/assets/whitelabel/bofa/bofa-nova-text.svg"
                height="70"
              />
            </div>
          </div>

          <template v-for="section in sections">
            <div
              v-if="section.buttons && section.buttons.length > 0"
              :key="section.name"
              :style="{
                width: BofAApplicationSidebarWidth,
                paddingTop: BofASidebarSectionTitlePadding,
                paddingBottom: BofASidebarSectionTitlePadding,
              }"
              class="position-relative d-flex flex-column align-items-center"
            >
              <span class="text-primary font-weight-600"> {{ section.name }}</span>
              <SidebarButton
                v-for="button of section.buttons"
                :key="button.step.route"
                :option="button"
                :current-step="currentStep"
                :should-show-extended="isHovered"
              />
            </div>
          </template>
        </div>

        <div
          v-if="shouldShowHideBetaFeaturesButtonBofA"
          class="p-2 text-primary"
        >
          <b-form-checkbox
            v-model="shouldHideBetaFeatures"
            data-testid="should-hide-beta-features-checkbox"
            name="should-hide-beta-features-button"
            switch
            class="d-flex align-items-center"
          >
            Hide Beta Features
          </b-form-checkbox>
        </div>
      </template>
    </b-sidebar>
    <AnalysisToolModal />
  </div>
</template>

<script lang="ts">
import {
  BofAApplicationSidebarWidth,
  BofAExpandedSideBarWidth,
  BofASidebarSectionTitlePadding,
  BofASidebarPadding,
  BofASidebarLogoPaddingLeft,
} from '@/constants/SidebarWidths';
import {
  FOLDER,
  CLUSTERING_ANALYSIS,
  FACTSHEET,
  PERFORMANCE_ATTRIBUTION,
  CONSTITUENT_RISK,
  BASKET,
  POSITION,
} from '@/types/analytics/AnalysisStep';
import { Data, Insights, Resources, MyLab, AdminConsole, UserSettings } from '@/types/NavItem';
import { FACTOR_DECOMPOSITION } from '@/types/analytics/FactorDecomposition';
import { PORTFOLIO_CONSTRUCTION } from '@/types/analytics/PortfolioConstruction';
import { BSidebar } from 'bootstrap-vue';
import AnalysisToolModal from '@/views/modals/AnalysisToolModal.vue';
import { UserPermission } from '@/constants/UserPermission';
import { RouteName, portfolioPages } from '@/constants/RouteName';
import useGlobalEventBus from '@/composables/useGlobalEventBus';
import { computed, defineComponent, ref, watch } from 'vue';
import usePortfolioTree from '@/composables/usePortfolioTree';
import { Location } from 'vue-router';
import { useHasPermission } from '@/composables/usePermission';
import { useRouteRef } from '@/composables/useRouter';
import { isPortfolioTreeSubportfolio } from '@/types/IPortfolioTree';
import { isBasketTypePortfolioFn, isConstituentPortfolioFn } from '@/utils/portfolioTree';
import { RiskTabs } from '@/types/analytics/ConstituentRisk';
import { useFeatureFlag } from '@/composables/useFeatureFlag';
import { useIndexUniqueIdentifier } from '@/composables/useCorrectIdentifier';
import { useAnalysisStepItems } from '@/composables/useAnalysisSteps';
import { UserModule } from '@/store/modules/user';
import useRouteChecks, { hasAnalyticsPermission } from '@/composables/useRouteChecks';
import useUser from '@/composables/useUser';
import { SidebarNavButton, ApplicationSidebarSection, SidebarAnalyticsButton } from '@/types/SidebarButton';
import SidebarButton from '@/whitelabel/bofa/components/sidebar/SidebarButton.vue';
import useTranslation from '@/composables/useTranslation';
import ScreenSizeComponent from '@/layout/components/navbar/ScreenSizeComponent.vue';
import { useDataDiscoveryForBofA } from '@/composables/queries/useDataDiscovery';
import { useDiscoverStore } from '@/composables/useDiscoverStore';
import { PortfolioConstructionTabs } from '@/types/PortfolioConstructionTabs';
import { useLastUsedPortfolioSlug } from '@/composables/useCachedAnalysisParameters';

export default defineComponent({
  name: 'ApplicationSidebarBofA',
  components: {
    BSidebar,
    AnalysisToolModal,
    SidebarButton,
    ScreenSizeComponent,
  },
  setup() {
    /**
     * UNIVERSE INSTANTIATION SINGLETON
     *
     * This logic is not truly used in this page, but the index universe is used extensively throughout the application
     * Here we instantiate it once and when it changes, we update the Discover Store.
     *
     * This is the way we must move forward to comply with tanstack-query's requirements to v5 because the
     * useQuery's `onSuccess` callback is being deprecated.
     */
    const { setUniverse } = useDiscoverStore();
    const universe = useDataDiscoveryForBofA();
    watch(universe.data, (newVal) => {
      // Use structuredClone to force the object to not be reactive
      if (newVal) setUniverse(structuredClone(newVal));
    });

    const route = useRouteRef();
    const indexUniqueIdentifier = useIndexUniqueIdentifier();
    const { eventBus } = useGlobalEventBus();
    const { masterPortfolioTree, itemUnderAnalysis, isMasterIndex } = usePortfolioTree();
    const { user, isUserAdmin } = useUser();
    const { translate } = useTranslation();
    const isBasket = isBasketTypePortfolioFn(masterPortfolioTree);

    const { portfolio: lastPortfolioSlug } = useLastUsedPortfolioSlug();

    const { isOnBasketPage } = useRouteChecks(route);

    const {
      canSeeMyLab,
      isReadOnlyPlatform,
      shouldHideBetaFeatures,
      shouldShowHideBetaFeaturesButtonBofA,
      hasConstituentRiskSimulationAccess,
    } = useFeatureFlag();

    // Permissions
    const hasPortfolioConstructionPermission = useHasPermission(UserPermission.PORTFOLIO);
    const hasPcaPermission = useHasPermission(UserPermission.PCA);
    const hasRegressionPermission = useHasPermission(UserPermission.REGRESSION);
    const hasConstituentPermission = useHasPermission(UserPermission.CONSTITUENT);
    const hasEquityBasketPermission = useHasPermission(UserPermission.EQUITY_BASKET_CREATION);
    const impersonating = useHasPermission(UserPermission.IMPERSONATE);
    const isAdmin = useHasPermission(UserPermission.ADMIN);
    const unblock = useHasPermission(UserPermission.UNBLOCK);
    const platform = useHasPermission(UserPermission.PLATFORM);
    const hasUnblockPermission = useHasPermission(UserPermission.UNBLOCK);
    const hasPlatformPermission = useHasPermission(UserPermission.PLATFORM);
    const hasApiPermission = useHasPermission(UserPermission.API);
    const isDropzoneAccessible = useHasPermission(UserPermission.DROP_ZONE);
    const isMorningstarAccessible = useHasPermission(UserPermission.MORNING_STAR);
    const isRequestTableAccessible = useHasPermission(UserPermission.REQUEST_QIS);
    const isProxyTableAccessible = isUserAdmin;
    const hasBasicsPermission = computed(() => {
      return hasUnblockPermission.value && hasPlatformPermission.value;
    });
    const hasAnyAnalyticsPermission = computed(
      () =>
        UserModule.user !== null &&
        hasAnalyticsPermission({ user: UserModule.user, currentRoute: route.value }) !== undefined,
    );
    const shouldDisableMyLab = computed(() => {
      // Assume the user has access when the user not yet loaded to avoid the My Lab button flashing upon login
      if (!user.value) {
        return false;
      }
      return (
        !isDropzoneAccessible.value &&
        !isMorningstarAccessible.value &&
        !isRequestTableAccessible &&
        !isProxyTableAccessible.value
      );
    });

    const slugToUse = computed(() => {
      if (isMasterIndex.value) {
        return masterPortfolioTree.value?.portfolioTree.reference ?? indexUniqueIdentifier.value;
      }
      return indexUniqueIdentifier.value;
    });

    const { availableAnalysisStepItems } = useAnalysisStepItems(route);

    const isPortfolioPage = computed(
      () => route.value.name !== undefined && portfolioPages.has(route.value.name as RouteName),
    );

    const isHovered = ref(false);

    const currentApplicationSidebarWidth = computed(() =>
      isHovered.value ? BofAExpandedSideBarWidth : BofAApplicationSidebarWidth,
    );

    const analyticsRouteNames = computed(() => {
      return [
        PORTFOLIO_CONSTRUCTION,
        FACTOR_DECOMPOSITION,
        CLUSTERING_ANALYSIS,
        CONSTITUENT_RISK,
        PERFORMANCE_ATTRIBUTION,
        FACTSHEET,
        BASKET,
        POSITION,
      ];
    });

    const navItemRouteNames = computed(() => {
      return [Data, Insights, Resources, MyLab, AdminConsole, UserSettings];
    });

    const currentStep = computed(() => {
      const currentPath = route.value.path;

      for (const analyticsRoute of analyticsRouteNames.value) {
        if (
          route.value.name === analyticsRoute.name ||
          currentPath.includes(analyticsRoute.route) ||
          (analyticsRoute.secondaryRoute && route.value.path.includes(analyticsRoute.secondaryRoute))
        ) {
          return analyticsRoute;
        }
      }

      for (const navRoute of navItemRouteNames.value) {
        if (route.value.name === navRoute.name || currentPath.includes(navRoute.route)) {
          return navRoute;
        }
      }

      return Data;
    });

    const regressionRoute = computed(() => {
      const shouldUseStrategyRegression =
        route.value.name === RouteName.STRATEGY_FACTSHEET ||
        route.value.name === RouteName.FACTOR_DECOMPOSITION_STRATEGY ||
        route.value.name === RouteName.CONSTITUENT_RISK_STRATEGY ||
        route.value.name === RouteName.PERFORMANCE_ATTRIBUTION;

      return {
        name: shouldUseStrategyRegression
          ? RouteName.FACTOR_DECOMPOSITION_STRATEGY
          : RouteName.FACTOR_DECOMPOSITION_PORTFOLIO,
        params: {
          indexUniqueIdentifier: indexUniqueIdentifier.value,
        },
        query:
          itemUnderAnalysis.value && isPortfolioTreeSubportfolio(itemUnderAnalysis.value)
            ? {
                id: itemUnderAnalysis.value.portfolioTreeId,
              }
            : undefined,
      };
    });

    const isConstituentPortfolio = isConstituentPortfolioFn(masterPortfolioTree);

    /**
     * Excepting this unusual case, if we are on a Risk portfolio, then we allow them through.
     * Otherwise we show the Analysis Tool Modal so that they can select a Risk portfolio to analyze on the Risk page.
     */
    const constituentTo = computed((): Location | undefined => {
      // if it is a constituent portfolio or strategy, then allow them through
      if (hasConstituentRiskSimulationAccess.value || isConstituentPortfolio.value) {
        return {
          name: isPortfolioPage.value ? RouteName.CONSTITUENT_RISK_PORTFOLIO : RouteName.CONSTITUENT_RISK_STRATEGY,
          params: {
            indexUniqueIdentifier: indexUniqueIdentifier.value,
            substep: RiskTabs.VAR,
          },
        };
      }
      // otherwise we return undefined and let the onClick function handle it
      // and show to the user the Analysis Tool modal
      return undefined;
    });

    const getNavItemTo = (routeName: string) => {
      return {
        name: routeName,
      };
    };

    /**
     * If the current portfolio is NOT an equity basket portfolio, then we allow them through.
     * Otherwise if it is an equity basket portfolio, then we show the Analysis Tool Modal
     * so that they may select a regular portfolio to analyze on Portfolio Construction
     *
     * We are using lastPortfolioSlug here instead of indexUniqueIdentifier, which is different from src/layout/components/sidebar/ApplicationSidebar.vue
     * The reason being BofA platform doesn't have the Navbar, which contains the Analytics nav item.
     * which makes indexUniqueIdentifier not available in the context of the BofA platform.
     */
    const portfolioConstructionTo = computed((): Location | undefined => {
      if (!isBasket.value && lastPortfolioSlug.value) {
        return {
          name: RouteName.PORTFOLIO_CONSTRUCTION,
          params: {
            indexUniqueIdentifier: lastPortfolioSlug.value,
            substep: PortfolioConstructionTabs.SIMULATION,
          },
        };
      }
      return undefined;
    });

    /**
     * If the current portfolio IS an equity basket portfolio, then we allow them through.
     * Otherwise if it is not an equity basket portfolio, then we show the Analysis Tool Modal
     * so that they may select an equity basket portfolio to analyze on Equity Basket
     */
    const basketTo = computed((): Location | undefined => {
      // if it IS a basket or master index basket, then allow them through
      if (isBasket.value || isMasterIndex.value) {
        return {
          name: RouteName.BASKET,
          params: {
            indexUniqueIdentifier: slugToUse.value,
          },
        };
      }
      // otherwise we return undefined and let the onClick function handle it
      // and show to the user the Analysis Tool modal
      return undefined;
    });

    const factsheetTo = computed(() => {
      const shouldUseStrategyFactsheet =
        route.value.name === RouteName.STRATEGY_FACTSHEET ||
        route.value.name === RouteName.FACTOR_DECOMPOSITION_STRATEGY ||
        route.value.name === RouteName.PERFORMANCE_ATTRIBUTION ||
        route.value.name === RouteName.CONSTITUENT_RISK_STRATEGY ||
        route.value.name === RouteName.POSITION ||
        isMasterIndex.value;

      return {
        name: shouldUseStrategyFactsheet ? RouteName.STRATEGY_FACTSHEET : RouteName.PORTFOLIO_FACTSHEET,
        params: {
          indexUniqueIdentifier: slugToUse.value,
        },
      };
    });

    /*
     * By default 'Portfolio Selection' option will be available
     * */
    const analyticsItemSection = computed((): ApplicationSidebarSection => {
      let buttons: SidebarAnalyticsButton[] = [];

      const retval = {
        name: translate({ path: 'APPLICATION_SIDEBAR.ANALYTICS' }),
        buttons: buttons,
      };

      if (!hasBasicsPermission.value) return retval;

      buttons.push({
        step: FOLDER,
        onClick: () => eventBus.emit('show-analysis-tool-modal', route.value.name),
        onCtrlClick: () => {},
        disabled: !hasAnyAnalyticsPermission.value,
        nameToDisplay: translate({ path: 'APPLICATION_SIDEBAR.BASKET_LIST' }),
      });

      buttons.push({
        step: PORTFOLIO_CONSTRUCTION,
        to: portfolioConstructionTo.value,
        onClick: () => {
          if (!isBasket.value) {
            return;
          }
          eventBus.emit('show-analysis-tool-modal', RouteName.PORTFOLIO_CONSTRUCTION);
        },
        onCtrlClick: () => {},
        disabled: !hasPortfolioConstructionPermission.value || !hasAnyAnalyticsPermission.value,
        nameToDisplay: translate({ path: 'APPLICATION_SIDEBAR', item: RouteName.PORTFOLIO_CONSTRUCTION }),
      });

      buttons.push({
        step: BASKET,
        to: basketTo.value,
        onClick: () => {
          if (isBasket.value && isOnBasketPage.value) {
            return;
          }
          eventBus.emit('show-analysis-tool-modal', RouteName.BASKET);
        },
        onCtrlClick: () => {},
        disabled: !hasEquityBasketPermission.value || !hasAnyAnalyticsPermission.value,
        nameToDisplay: translate({ path: 'APPLICATION_SIDEBAR', item: RouteName.BASKET }),
      });

      buttons.push({
        step: CLUSTERING_ANALYSIS,
        to: {
          name: RouteName.CLUSTERING_ANALYSIS,
          params: {
            indexUniqueIdentifier: indexUniqueIdentifier.value,
          },
        },
        /**
         * For some reason the chart network icon appears wider than the rest
         * So we have to reduce the font-size and also re-position it...
         */
        style: {
          'font-size': '1.8em',
        },
        onClick: () => {},
        onCtrlClick: () => {},
        disabled: !hasPcaPermission.value || !hasAnyAnalyticsPermission.value,
        nameToDisplay: translate({ path: 'APPLICATION_SIDEBAR.CLUSTERING_ANALYSIS' }),
      });

      buttons.push({
        step: FACTOR_DECOMPOSITION,
        onClick: () => {},
        onCtrlClick: () => {},
        to: regressionRoute.value,
        disabled: !hasRegressionPermission.value,
        nameToDisplay: translate({ path: 'APPLICATION_SIDEBAR.FACTOR_DECOMPOSITION' }),
      });

      buttons.push({
        step: CONSTITUENT_RISK,
        to: constituentTo.value,
        onClick: () => eventBus.emit('show-analysis-tool-modal', RouteName.CONSTITUENT_RISK_PORTFOLIO),
        onCtrlClick: () => {},
        disabled: !hasConstituentPermission.value || !hasAnyAnalyticsPermission.value,
        nameToDisplay: translate({ path: 'APPLICATION_SIDEBAR.CONSTITUENT_RISK' }),
      });

      buttons.push({
        step: FACTSHEET,
        to: factsheetTo.value,
        onClick: () => {},
        onCtrlClick: () => {},
        disabled: !hasAnyAnalyticsPermission.value,
        nameToDisplay: translate({ path: 'APPLICATION_SIDEBAR.FACTSHEET' }),
      });

      buttons.push({
        step: PERFORMANCE_ATTRIBUTION,
        to: {
          name: RouteName.PERFORMANCE_ATTRIBUTION,
          params: {
            indexUniqueIdentifier: slugToUse.value,
          },
        },
        onClick: () => {},
        onCtrlClick: () => {},
        disabled: !hasAnyAnalyticsPermission.value,
        nameToDisplay: translate({ path: 'APPLICATION_SIDEBAR.PERFORMANCE_CONTRIBUTION' }),
      });

      buttons.push({
        step: POSITION,
        to: {
          name: RouteName.POSITION,
          params: {
            indexUniqueIdentifier: slugToUse.value,
          },
        },
        onClick: () => {},
        onCtrlClick: () => {},
        disabled: !hasAnyAnalyticsPermission.value,
        nameToDisplay: translate({ path: 'APPLICATION_SIDEBAR.POSITION' }),
      });

      // Filter out the analyticsOptions that are not available
      buttons = buttons.filter((option) => availableAnalysisStepItems.value.includes(option.step));

      retval.buttons = buttons;
      return retval;
    });

    const dataSection = computed((): ApplicationSidebarSection => {
      return {
        name: translate({ path: 'APPLICATION_SIDEBAR.DATA' }),
        buttons: [
          {
            step: Data,
            to: Data,
            onClick: () => {},
            onCtrlClick: () => {},
            // TODO: Handle disable referencing app navbar
            disabled: false,
            nameToDisplay: translate({ path: 'APPLICATION_SIDEBAR.CATALOGUE' }),
          },
        ],
      };
    });

    const insightSection = computed((): ApplicationSidebarSection => {
      return {
        name: translate({ path: 'APPLICATION_SIDEBAR.CONTENT' }),
        buttons: [
          {
            step: Insights,
            to: getNavItemTo(Insights.name),
            onClick: () => {},
            onCtrlClick: () => {},
            disabled: false,
            nameToDisplay: translate({ path: 'APPLICATION_SIDEBAR.INSIGHTS' }),
          },
        ],
      };
    });

    const navItemSection = computed((): ApplicationSidebarSection => {
      const buttons: SidebarNavButton[] = [];

      if (hasBasicsPermission.value && canSeeMyLab.value) {
        buttons.push({
          step: MyLab,
          to: getNavItemTo(MyLab.name),
          onClick: () => {},
          onCtrlClick: () => {},
          disabled: shouldDisableMyLab.value,
          nameToDisplay: translate({ path: 'APPLICATION_SIDEBAR.DROPZONE' }),
        });
      }

      if (hasApiPermission.value || hasUnblockPermission.value) {
        buttons.push({
          step: Resources,
          to: getNavItemTo(Resources.name),
          onClick: () => {},
          onCtrlClick: () => {},
          disabled: false,
          nameToDisplay: translate({ path: 'APPLICATION_SIDEBAR.GLOSSARY' }),
        });
      }

      return {
        name: translate({ path: 'APPLICATION_SIDEBAR.MY_LAB' }),
        buttons: buttons,
      };
    });

    const adminItemSection = computed((): ApplicationSidebarSection => {
      const buttons: SidebarNavButton[] = [];
      const adminSectionNameToDisplay = translate({ path: 'APPLICATION_SIDEBAR.SETTINGS' });

      if (isAdmin.value && !impersonating.value) {
        buttons.push({
          step: AdminConsole,
          to: getNavItemTo(AdminConsole.name),
          onClick: () => {},
          onCtrlClick: () => {},
          disabled: false,
          nameToDisplay: translate({ path: 'APPLICATION_SIDEBAR', item: AdminConsole.name }),
        });
      }

      if (unblock.value && platform.value && !isReadOnlyPlatform.value) {
        buttons.push({
          step: UserSettings,
          to: getNavItemTo(UserSettings.name),
          onClick: () => {},
          onCtrlClick: () => {},
          disabled: false,
          nameToDisplay: translate({ path: 'APPLICATION_SIDEBAR', item: UserSettings.name }),
        });
      }

      return {
        name: adminSectionNameToDisplay,
        buttons: buttons,
      };
    });

    const sections = computed(() => [
      insightSection.value,
      dataSection.value,
      analyticsItemSection.value,
      navItemSection.value,
      adminItemSection.value,
    ]);

    return {
      currentApplicationSidebarWidth,
      isHovered,
      currentStep,
      sections,
      BofAApplicationSidebarWidth,
      BofAExpandedSideBarWidth,
      BofASidebarSectionTitlePadding,
      BofASidebarPadding,
      BofASidebarLogoPaddingLeft,
      shouldHideBetaFeatures,
      shouldShowHideBetaFeaturesButtonBofA,
      indexUniqueIdentifier,
    };
  },
});
</script>
<style lang="scss">
.bofa-application-sidebar {
  top: 0;
  height: 100% !important;
}

/**
 * b-sidebar has an overflow-x: auto that causes a horizontal scrollbar to appear
 * Remove the horizontal scrollbar from the sidebar
 */
.b-sidebar > div {
  overflow-x: hidden;
}

.inner {
  transition: width 1s ease-in-out;
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
}
</style>
