import {
  DataDiscoveryMetricsRequestDTO,
  DataDiscoveryMetricsResponseDTO,
  DataDiscoveryMetricsVolScaledResponseDTO,
  DataDiscoveryResponseDTO,
  DataDiscoveryVolScaledPortfolioMetricsRequestDTO,
  DataDiscoveryVolScaledStrategyMetricsRequestDTO,
  getDataUniverse,
  getDataUniverseMetrics,
  getQuantSpacePositions,
  getPortfolioMetrics,
  getPortfolioSummary,
  getStrategyMetrics,
  PortfolioItemResponseDTO,
  PortfolioSummaryResponseDTO,
  StrategyItemResponseDTO,
} from '@/api-v2/web/discover';
import { TimeSeriesEntityTypeConstants } from '@/constants/TimeSeriesEntityTypeConstants';
import { DataTypes, normalizePortfolioType, normalizeType } from '@/types/setting';
import { VQQueryOptions } from '@/types/VueQueryTypes';
import { useQuery } from '@tanstack/vue-query';
import { computed, ref, Ref } from 'vue';
import { useQueriesRef } from './useQueriesRef';
import {
  DataDiscoveryUniverseQuantSpaceRequestDTO,
  DataDiscoveryUniverseQuantSpaceResponseDTO,
} from '@/api-v2/web/discover/types/DataDiscoveryQuantSpaceDTO';

const keys = {
  /**
   * Note: keep this in sync with `discoveryAllKey()` in {@link file://./useStrategies.ts} and
   * {@link file://./usePortfolioTreeData.ts}.
   */
  all: () => [{ scope: 'discover' }] as const,
  portfolioSummary: () => [{ ...keys.all()[0], entity: 'portfolio-summary' }],
  metrics: (query: Ref<DataDiscoveryMetricsRequestDTO>) => [{ ...keys.all()[0], entity: 'metrics', query }],
  quantSpacePositions: (query: Ref<DataDiscoveryUniverseQuantSpaceRequestDTO>) => [
    { ...keys.all()[0], entity: 'quant-space-positions', query },
  ],
  strategyMetrics: (query: Ref<DataDiscoveryVolScaledStrategyMetricsRequestDTO>) => [
    { ...keys.all()[0], entity: 'strategy-metrics', query },
  ],
  portfolioMetrics: (query: Ref<DataDiscoveryVolScaledPortfolioMetricsRequestDTO>) => [
    { ...keys.all()[0], entity: 'portfolio-metrics', query },
  ],
};

const select = {
  allStrategies: (universe: DataDiscoveryResponseDTO) => {
    return universe.strategy ?? [];
  },
  universeForBofA: (universe: DataDiscoveryResponseDTO) => {
    const filteredStrategies = (universe.strategy ?? []).filter((s) =>
      [
        TimeSeriesEntityTypeConstants.STRATEGY,
        TimeSeriesEntityTypeConstants.BENCHMARK,
        TimeSeriesEntityTypeConstants.PRIVATE_STRATEGY,
        TimeSeriesEntityTypeConstants.THEMATIC,
        TimeSeriesEntityTypeConstants.PUBLIC_THEMATIC,
      ].includes(s.type),
    );
    return {
      strategy: filteredStrategies,
      portfolio: [],
    };
  },
  allStrategiesById: (universe: DataDiscoveryResponseDTO) => {
    return new Map((universe.strategy ?? []).map((strategy) => [strategy.id, strategy]));
  },
  allStrategiesByCode: (universe: DataDiscoveryResponseDTO) => {
    return new Map((universe.strategy ?? []).map((strategy) => [strategy.code, strategy]));
  },
  allPortfolios: (universe: DataDiscoveryResponseDTO) => {
    return universe.portfolio ?? [];
  },
  allPortfoliosById: (universe: DataDiscoveryResponseDTO) => {
    return new Map((universe.portfolio ?? []).map((portfolio) => [portfolio.portfolioId, portfolio]));
  },
  allPortfoliosBySlug: (universe: DataDiscoveryResponseDTO) => {
    return new Map((universe.portfolio ?? []).map((portfolio) => [portfolio.slug, portfolio]));
  },
};

/**
 * Get the whole universe
 */
export function useDataDiscovery(options: VQQueryOptions<DataDiscoveryResponseDTO> = {}) {
  return useQuery({
    queryKey: keys.all(),
    queryFn: () => getDataUniverse(),
    ...options,
    staleTime: Number.POSITIVE_INFINITY,
    refetchOnWindowFocus: false,
  });
}

/**
 * On the BofA environment, we only want to show QIS strategies and benchmarks
 * this includes the types: Strategy, Benchmark, Private Strategy
 * This should only be the case on the data universe page, on the DSB we want to show all
 * the time series entities available to them
 */
export function useDataDiscoveryForBofA(options: VQQueryOptions<DataDiscoveryResponseDTO> = {}) {
  return useQuery({
    queryKey: keys.all(),
    queryFn: () => getDataUniverse(),
    select: select.universeForBofA,
    ...options,
    staleTime: Number.POSITIVE_INFINITY,
    refetchOnWindowFocus: false,
  });
}

/**
 * Get all strategies.
 */
export function useAllStrategies(options: VQQueryOptions<StrategyItemResponseDTO[]> = {}) {
  return useQuery({
    queryKey: keys.all(),
    queryFn: () => getDataUniverse(),
    select: select.allStrategies,
    ...options,
    staleTime: Number.POSITIVE_INFINITY,
    refetchOnWindowFocus: false,
  });
}

/**
 * Get a map of strategy id -> strategy
 */
export function useAllStrategiesById(options: VQQueryOptions<Map<string, StrategyItemResponseDTO>> = {}) {
  return useQuery({
    queryKey: keys.all(),
    queryFn: () => getDataUniverse(),
    select: select.allStrategiesById,
    ...options,
    staleTime: Number.POSITIVE_INFINITY,
    refetchOnWindowFocus: false,
  });
}

/**
 * Get a map of strategy code -> strategy
 */
export function useAllStrategiesByCode(options: VQQueryOptions<Map<string, StrategyItemResponseDTO>> = {}) {
  return useQuery({
    queryKey: keys.all(),
    queryFn: () => getDataUniverse(),
    select: select.allStrategiesByCode,
    ...options,
    staleTime: Number.POSITIVE_INFINITY,
    refetchOnWindowFocus: false,
  });
}

/**
 * Get an array of strategy of given type.
 *
 * Note that the type parameter is not a ref/reactive. Because we are
 * implementing the filter logic in `select` and reactivity might not work with
 * it. So we are playing safe here.
 *
 * This also exclude some DataTypes which are not queried by the same API.
 */
export function useStrategyByType(
  type: Exclude<DataTypes, DataTypes.SIGNAL | DataTypes.PORTFOLIO>,
  options: VQQueryOptions<StrategyItemResponseDTO[]> = {},
) {
  return useQuery({
    queryKey: keys.all(),
    queryFn: () => getDataUniverse(),
    ...options,
    select(universe) {
      return (universe.strategy ?? []).filter((s) => normalizeType(s) === type);
    },
    staleTime: Number.POSITIVE_INFINITY,
    refetchOnWindowFocus: false,
  });
}

/**
 * Get all portfolios.
 * For a map of portfolios, use {@link useAllPortfoliosBySlug} instead
 */
export function useAllPortfolios(options: VQQueryOptions<PortfolioItemResponseDTO[]> = {}) {
  return useQuery({
    queryKey: keys.all(),
    queryFn: () => getDataUniverse(),
    select: select.allPortfolios,
    ...options,
    staleTime: Number.POSITIVE_INFINITY,
    refetchOnWindowFocus: false,
  });
}

/**
 * Get a map of portfolio id -> portfolio
 */
export function useAllPortfoliosById(options: VQQueryOptions<Map<string, PortfolioItemResponseDTO>> = {}) {
  return useQuery({
    queryKey: keys.all(),
    queryFn: () => getDataUniverse(),
    select: select.allPortfoliosById,
    ...options,
    staleTime: Number.POSITIVE_INFINITY,
    refetchOnWindowFocus: false,
  });
}

/**
 * Get a map of portfolio slug -> portfolio
 */
export function useAllPortfoliosBySlug(options: VQQueryOptions<Map<string, PortfolioItemResponseDTO>> = {}) {
  return useQuery({
    queryKey: keys.all(),
    queryFn: () => getDataUniverse(),
    select: select.allPortfoliosBySlug,
    ...options,
    staleTime: Number.POSITIVE_INFINITY,
    refetchOnWindowFocus: false,
  });
}

/**
 * Get an array of portfolios of given type.
 *
 * Note that the type parameter is not a ref/reactive. Because we are
 * implementing the filter logic in `select` and reactivity might not work with
 * it. So we are playing safe here.
 */
export function usePortfolioByType(
  type: DataTypes.PORTFOLIO | DataTypes.BENCHMARK,
  options: VQQueryOptions<PortfolioItemResponseDTO[]> = {},
) {
  return useQuery({
    queryKey: keys.all(),
    queryFn: () => getDataUniverse(),
    ...options,
    select(universe) {
      return (universe.portfolio ?? []).filter((o) => normalizePortfolioType(o) === type);
    },
    staleTime: Number.POSITIVE_INFINITY,
    refetchOnWindowFocus: false,
  });
}

/**
 * Gets a summary of portfolios which strategies can be added to.
 */
export function usePortfolioSummary(options: VQQueryOptions<PortfolioSummaryResponseDTO[]> = {}) {
  return useQuery({
    queryKey: keys.portfolioSummary(),
    queryFn: () => getPortfolioSummary(),
    ...options,
    staleTime: Number.POSITIVE_INFINITY,
    refetchOnWindowFocus: false,
  });
}

/**
 * Get metrics data for all indices. Calculation method: generated tracks and metrics from the cache data from Redis.
 */
export function useDiscoverMetrics(
  query: Ref<DataDiscoveryMetricsRequestDTO>,
  options: VQQueryOptions<DataDiscoveryMetricsResponseDTO> = {},
) {
  return useQuery({
    queryKey: keys.metrics(query),
    queryFn: () => getDataUniverseMetrics(query.value),
    ...options,
    staleTime: Number.POSITIVE_INFINITY,
    refetchOnWindowFocus: false,
  });
}

export function useQuantSpacePositions(
  query: Ref<DataDiscoveryUniverseQuantSpaceRequestDTO>,
  options: VQQueryOptions<DataDiscoveryUniverseQuantSpaceResponseDTO> = {},
) {
  return useQuery({
    queryKey: keys.quantSpacePositions(query),
    queryFn: () => getQuantSpacePositions(query.value),
    ...options,
    staleTime: Number.POSITIVE_INFINITY,
    refetchOnWindowFocus: false,
  });
}

/**
 *  Get metrics data for strategies for a given volScaling setting. Calculation method: apply volScaling and ensure its calculated by C++.
 */
export function useDiscoverStrategyMetrics(
  queries: Ref<DataDiscoveryVolScaledStrategyMetricsRequestDTO[]>,
  options: VQQueryOptions<DataDiscoveryMetricsVolScaledResponseDTO> = {},
) {
  return useQueriesRef({
    queries: computed((): Array<VQQueryOptions<DataDiscoveryMetricsVolScaledResponseDTO>> => {
      return queries.value.map((query) => ({
        queryKey: keys.strategyMetrics(ref(query)),
        queryFn: () => getStrategyMetrics(query),
        ...options,
        staleTime: Number.POSITIVE_INFINITY,
        refetchOnWindowFocus: false,
      }));
    }),
  });
}

/**
 *  Get metrics data for portfolios for a given volScaling setting. Calculation method: apply volScaling and ensure its calculated by C++.
 */
export function useDiscoverPortfolioMetrics(
  queries: Ref<DataDiscoveryVolScaledPortfolioMetricsRequestDTO[]>,
  options: VQQueryOptions<DataDiscoveryMetricsVolScaledResponseDTO> = {},
) {
  return useQueriesRef({
    queries: computed((): Array<VQQueryOptions<DataDiscoveryMetricsVolScaledResponseDTO>> => {
      return queries.value.map((query) => ({
        queryKey: keys.portfolioMetrics(ref(query)),
        queryFn: () => getPortfolioMetrics(query),
        ...options,
        staleTime: Number.POSITIVE_INFINITY,
        refetchOnWindowFocus: false,
      }));
    }),
  });
}
