import { IRollingTrackResponse } from '@/types/IRollingTrackResponse';
import { computed, ref, Ref } from 'vue';
import { VQQueryOptions } from '@/types/VueQueryTypes';
import { IStrategyRollingTrackGenericQuery } from '@/types/IStrategyRollingTrackGenericQuery';
import { useQuery, UseQueryOptions } from '@tanstack/vue-query';
import {
  getStrategyBenchmarkCorrelation,
  getStrategyPrice,
  getStrategyReturnRegression,
  getStrategyRollingTrack,
  getStrategyTrack,
  StrategyTrackResponseDTO,
  StrategyPriceResponseDTO,
  getFxConvertedPriceByDate,
  getStrategyKDE,
} from '@/api-v2/web/strategies';
import { IRollingTrackQuery } from '@/types/IRollingTrackQuery';
import { useQueriesRef } from './useQueriesRef';
import { IBenchmarkCorrelationResponseDatum } from '@/types/IBenchmarkCorrelationResponseDatum';
import { IBenchmarkCorrelationGenericQuery } from '@/types/IBenchmarkCorrelationGenericQuery';
import { ITrackQuery } from '@/types/ITrackQuery';
import { chainEnabled, unwrap } from '@/utils/queries';
import { ReturnRegressionStrategyRequestDTO } from '@/api-v2/web/active-return/types/ReturnRegressionStrategyRequestDTO';
import { IReturnRegressionResponse } from '@/types/IReturnRegressionResponse';
import { GetTracksDTO } from '@/api-v2/web/strategies/types/GetTracksDTO';
import { FxConvertedTrackMap } from '@/api-v2/web/strategies/types/GetTracksResponseDTO';
import useLoadingWhenEnabledQuery from './useLoadingWhenEnabledQuery';
import { IKdeResponseDto } from '@/types/IKdeResponseDto';
import { IStrategyKdeQuery } from '@/types/IStrategyKdeQuery';
import { TrackValueTypeConstants } from '@/constants/TrackValueTypeConstants';

const keys = {
  all: () => [{ scope: 'strategy' }] as const,
  track: (code: Ref<string | null>, query?: Ref<ITrackQuery>) =>
    [{ ...keys.all()[0], entity: 'track', code, query }] as const,
  rollingTrack: (code: Ref<string | null>, query: Ref<IRollingTrackQuery>) =>
    [{ ...keys.all()[0], entity: 'rolling-track-strategy', code, query }] as const,
  correlation: (params: Ref<IBenchmarkCorrelationGenericQuery>) =>
    [{ ...keys.all()[0], entity: 'correlation', params }] as const,
  returnRegression: (symbol: Ref<string | null>, query: Ref<ReturnRegressionStrategyRequestDTO | null>) =>
    [{ ...keys.all()[0], entity: 'return-regression-strategy', symbol, query }] as const,
  price: (code: Ref<string | undefined>) => [{ ...keys.all()[0], entity: 'strategy-price', code }] as const,
  fxConvertedPricesByDate: (codes: Ref<string[]>, query: Ref<GetTracksDTO>) =>
    [{ ...keys.all()[0], entity: 'fx-converted-strategies-price-by-date', codes, query }] as const,
  strategyTrack: (code: Ref<string | null | undefined>, query?: Ref<ITrackQuery | null>) =>
    [{ ...keys.all()[0], entity: 'strategy-track', code, query }] as const,
  kde: (code: Ref<string | null | undefined>, query?: Ref<IStrategyKdeQuery | null>) =>
    [{ ...keys.all()[0], entity: 'kde', code, query }] as const,
};

export function useStrategyTrack(
  code: Ref<string | null | undefined>,
  query?: Ref<ITrackQuery | null>,
  options: VQQueryOptions<StrategyTrackResponseDTO> = {},
) {
  const enabled = chainEnabled(
    options.enabled,
    computed(() => {
      // prevent query when start date equals end date
      const invalidDateCombination =
        query?.value && query.value.startDates.length === 1 && query.value.startDates[0] === query.value.endDate;
      return !!code.value && !invalidDateCombination && (!query || !!query.value);
    }),
  );

  return useLoadingWhenEnabledQuery(
    useQuery({
      queryKey: keys.strategyTrack(code, query),
      queryFn: () => getStrategyTrack(unwrap(code), unwrap(query)),
      ...options,
      enabled,
      staleTime: Number.POSITIVE_INFINITY,
    }),
    enabled,
  );
}

export function useStrategyTracks(
  codes: Ref<Array<string>>,
  query?: Ref<ITrackQuery>,
  options: VQQueryOptions<StrategyTrackResponseDTO> = {},
) {
  return useQueriesRef({
    queries: computed((): Array<UseQueryOptions<StrategyTrackResponseDTO>> => {
      return codes.value.map((code) => ({
        queryKey: keys.strategyTrack(ref(code), query),
        queryFn: () => getStrategyTrack(code, query?.value),
        ...options,
        staleTime: Number.POSITIVE_INFINITY,
      }));
    }),
  });
}

export function useStrategyRollingTracksData(
  parameters: Ref<Array<IStrategyRollingTrackGenericQuery>>,
  options: VQQueryOptions<IRollingTrackResponse> = {},
) {
  return useQueriesRef({
    queries: computed((): Array<UseQueryOptions<IRollingTrackResponse>> => {
      return parameters.value.map((param) => ({
        ...options,
        queryKey: keys.rollingTrack(ref(param.code), ref(param.query)),
        queryFn: () => getStrategyRollingTrack(param.code, param.query),
        staleTime: Number.POSITIVE_INFINITY,
      }));
    }),
  });
}

export function useStrategiesCorrelationData(
  parameters: Ref<Array<IBenchmarkCorrelationGenericQuery>>,
  options: VQQueryOptions<IBenchmarkCorrelationResponseDatum> = {},
) {
  return useQueriesRef({
    queries: computed((): Array<VQQueryOptions<IBenchmarkCorrelationResponseDatum>> => {
      return parameters.value.map((param) => ({
        queryKey: keys.correlation(ref({ code: param.code, query: param.query })),
        queryFn: () => getStrategyBenchmarkCorrelation(param),
        ...options,
        // This data shouldn't be updated using a timeout
        staleTime: Number.POSITIVE_INFINITY,
      }));
    }),
  });
}

export function useStrategyReturnRegressionData(
  symbol: Ref<string | null>,
  query: Ref<ReturnRegressionStrategyRequestDTO | null>,
  options: VQQueryOptions<IReturnRegressionResponse> = {},
) {
  const enabled = chainEnabled(
    options.enabled,
    computed(() => !!symbol.value && !!query.value),
  );
  return useLoadingWhenEnabledQuery(
    useQuery({
      queryKey: keys.returnRegression(symbol, query),
      queryFn: () => getStrategyReturnRegression(unwrap(symbol), unwrap(query)),
      ...options,
      enabled,
      staleTime: Number.POSITIVE_INFINITY,
    }),
    enabled,
  );
}

export function useStrategyPrices(
  codes: Ref<string[]>,
  valueType?: Ref<TrackValueTypeConstants>,
  options: VQQueryOptions<StrategyPriceResponseDTO[]> = {},
) {
  return useQueriesRef({
    queries: computed((): Array<VQQueryOptions<StrategyPriceResponseDTO[]>> => {
      return codes.value.map((code) => ({
        ...options,
        queryKey: keys.price(ref(code)),
        queryFn: () => getStrategyPrice(code, valueType?.value),
        staleTime: Number.POSITIVE_INFINITY,
      }));
    }),
  });
}

export function useFxConvertedStrategyPricesByDate(
  codes: Ref<string[]>,
  query: Ref<GetTracksDTO>,
  options: VQQueryOptions<FxConvertedTrackMap[]> = {},
) {
  const enabled = chainEnabled(
    options.enabled,
    computed(() => !!codes.value.length && !!query.value),
  );

  return useLoadingWhenEnabledQuery(
    useQuery({
      queryKey: keys.fxConvertedPricesByDate(codes, query),
      queryFn: () => getFxConvertedPriceByDate(unwrap(query), unwrap(codes.value.toString())),
      ...options,
      enabled,
      staleTime: Number.POSITIVE_INFINITY,
    }),
    enabled,
  );
}

export function useStrategyKde(
  code: Ref<string | null | undefined>,
  query?: Ref<IStrategyKdeQuery | null>,
  options: VQQueryOptions<IKdeResponseDto> = {},
) {
  const enabled = chainEnabled(
    options.enabled,
    computed(() => {
      return !!code.value && (!query || !!query.value);
    }),
  );

  return useLoadingWhenEnabledQuery(
    useQuery({
      queryKey: keys.kde(code, query),
      queryFn: () => getStrategyKDE(unwrap(code), unwrap(query)),
      ...options,
      enabled,
      staleTime: Number.POSITIVE_INFINITY,
    }),
    enabled,
  );
}
