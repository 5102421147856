import request, { checkBlockPopup } from '@/api-v2/request';
import { CustomStressTest, IConstituentRiskOptions } from '@/types/analytics/ConstituentRisk';
import { FileSignedUrlDTO } from '@/types/dto/FileSignedUrlDTO';
import {
  IConstituentRibbonQuery,
  IConstituentRisk,
  IConstituentRiskQuery,
  IConstituentRiskTableQuery,
} from '@/types/IConstituentRisk';
import { IConstituentRiskRibbonResponse } from '@/types/IConstituentRiskRibbon';
import { FileService } from '@/api-v2/web/file';
import { RouteName } from '@/constants/RouteName';
import { useDefaultPdfOptions } from '@/composables/usePdf';
import { Locales } from '@/constants/Locales';

/**
 * Fetches the constituent risk data
 */
export const getConstituentRiskData = (query: IConstituentRiskQuery): Promise<IConstituentRisk> =>
  request({
    url: `/risk-dashboard/dashboard`,
    method: 'post',
    data: query,
  }).then(
    (o): IConstituentRisk => o.data,
    (err) => {
      throw err;
    },
  );

/**
 * Fetches the constituent risk tables
 */
export const getConstituentRiskTable = (query: IConstituentRiskTableQuery): Promise<IConstituentRisk> =>
  request({
    url: `/risk-dashboard/dashboard/risk-table`,
    method: 'post',
    data: query,
  }).then((o): IConstituentRisk => o.data);

/**
 * Fetches the constituent risk ribbon
 */
export const getConstituentRiskRibbon = (query: IConstituentRibbonQuery): Promise<IConstituentRiskRibbonResponse[]> =>
  request({
    url: `/risk-dashboard/dashboard/ribbon`,
    method: 'post',
    data: query,
  }).then((o): IConstituentRiskRibbonResponse[] => o.data);

/**
 * Fetches the list of available dates for the portfolio.
 * @param slug portfolio slug name
 * @returns Array of date strings. String Format: ('YYYY-MM-DD')
 */
export const getConstituentRiskDates = (slug: string): Promise<string[]> =>
  request({
    url: `/risk-dashboard/${slug}/dates`,
    method: 'get',
  }).then(
    (o): string[] => o.data,
    (err) => {
      throw err;
    },
  );

/**
 * Fetches the list of available fields for each tab.
 */
export const getConstituentRiskOptions = (): Promise<IConstituentRiskOptions> =>
  request({
    url: `/risk-dashboard/options`,
    method: 'get',
  }).then(
    (o): IConstituentRiskOptions => o.data,
    (err) => {
      throw err;
    },
  );

/**
 * Constituent Risk Excel
 * @param slug portfolio slug name
 * @param date portfolio constituent date
 * @param
 */
export const getExcel = async (
  slug: string,
  date: string,
  indexName: string,
  filterNullQuantityPosition: boolean,
): Promise<void> => {
  const dto = await request({
    url: `/risk-dashboard/${slug}/excel`,
    method: 'POST',
    params: {
      date,
      filterNullQuantityPosition,
    },
  }).then((o) => o.data as FileSignedUrlDTO);

  if (!dto.signedURL) return;
  checkBlockPopup();

  await FileService.downloadWithCloudFrontUrl(dto.signedURL, 'xlsx', {
    tool: RouteName.CONSTITUENT_RISK,
    indexName,
    date,
  });
};

const defaultPdfOptions = useDefaultPdfOptions();

/**
 * Constituent Risk PDF
 * @param slug portfolio slug name
 * @param date portfolio constituent date
 * @param indexName name of the index, masked if appropriate
 */
export const getPdf = async (
  slug: string,
  url: string,
  params: {
    /**
     * Stringified preferences
     */
    preferences: string;
    startDate: string;
    locale: Locales;
  },
  indexName: string,
): Promise<void> => {
  const resp = await request({
    url: `/risk-dashboard/${slug}/pdf`,
    method: 'post',
    data: {
      url,
      params,
      options: defaultPdfOptions,
    },
  });
  const dto: FileSignedUrlDTO = resp.data;
  if (!dto.signedURL) return;
  checkBlockPopup();

  await FileService.downloadWithCloudFrontUrl(dto.signedURL, 'pdf', {
    tool: RouteName.CONSTITUENT_RISK,
    indexName,
    date: params.startDate,
  });
};

/**
 * Extract all stress test option for the user
 */
export const getCustomStressTest = (): Promise<CustomStressTest[]> =>
  request({
    url: `/risk-dashboard/stress-test`,
    method: 'get',
  }).then((o) => o.data);

/**
 * Create a new stress test option
 */
export const uploadCustomStressTest = (body: CustomStressTest): Promise<CustomStressTest> =>
  request({
    url: `/risk-dashboard/stress-test`,
    method: 'post',
    data: body,
  }).then((o) => o.data);

/**
 * Create a new stress test option
 */
export const updateCustomStressTest = ({
  attributeId,
  body,
}: {
  attributeId: string;
  body: CustomStressTest;
}): Promise<CustomStressTest> =>
  request({
    url: `/risk-dashboard/stress-test/${attributeId}`,
    method: 'put',
    data: body,
  }).then((o) => o.data);

/**
 * Delete a stress test option
 */
export const deleteCustomStressTest = (attributeId: string): Promise<void> =>
  request({
    url: `/risk-dashboard/stress-test/${attributeId}`,
    method: 'delete',
  }).then((o) => o.data);
