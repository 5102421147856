import { Currency, getCurrencySymbol } from './constants/Currency';
import {
  MetricUnit,
  METRICS_DATABASE_NAME,
  BLM_METRICS_DATABASE_NAME,
  FACTSHEET_RISK_METRICS_DATABASE_NAME,
  MONTHLY_RETURN_DISTRIBUTION_METRICS_DATABASE_NAME,
} from './utils/metrics';

export function metricsFixedDecimal(
  value: number | undefined,
  field:
    | METRICS_DATABASE_NAME
    | BLM_METRICS_DATABASE_NAME
    | FACTSHEET_RISK_METRICS_DATABASE_NAME
    | MONTHLY_RETURN_DISTRIBUTION_METRICS_DATABASE_NAME,
  numDecimalPoints = 2,
  showPercent = true,
): string {
  if (typeof value !== 'number') return '-';
  const unit = MetricUnit[field];
  if (unit === '%') return `${(value * 100).toFixed(numDecimalPoints)}${!showPercent ? '' : unit}`;
  if (value !== null) return `${value.toFixed(numDecimalPoints)}${unit}`;
  return '';
}

export function numDecimalPoints(
  value: string | number | null | undefined,
  {
    numDecimalPoints = 2,
    percent = false,
    /**
     * Use shouldShowPlainZero if you want to show "0" instead of "0.00"
     */
    shouldShowPlainZero = false,
  }: { numDecimalPoints?: number; percent?: boolean; shouldShowPlainZero?: boolean } = {},
): string {
  if (value == null) return '';
  if (typeof value === 'string') {
    const float = parseFloat(value);
    if (isNaN(float)) return value;

    value = float;
  }
  if (percent) {
    return (value * 100).toFixed(numDecimalPoints) + '%';
  }
  if (shouldShowPlainZero && Math.abs(parseFloat(value.toFixed(numDecimalPoints))) === 0) {
    return '0';
  }
  return value.toFixed(numDecimalPoints);
}

export function units(
  value: number | string | undefined | null,
  unit: string,
  options: { currency?: Currency; shouldBeRounded?: boolean; numDecimalPoints?: number } = {},
): string {
  if (typeof value === 'undefined' || value === null) return '-';
  if (typeof value === 'string') return value;
  const percentage = Math.round((value + Number.EPSILON) * 1000) / 1000;
  const currency = Math.round(value);
  if (unit.startsWith('%')) return `${percentage.toLocaleString()}%`;
  if (unit.startsWith('$')) {
    const ccy = options?.currency ?? Currency.USD;
    return `${getCurrencySymbol(ccy)} ${currency.toLocaleString()}`;
  }

  if (options.shouldBeRounded) {
    const rounded = Math.round((value + Number.EPSILON) * 100) / 100;
    return `${rounded.toLocaleString()}`;
  }

  if (options.numDecimalPoints) {
    return `${value.toFixed(options.numDecimalPoints).toLocaleString()}`;
  }

  return `${value.toLocaleString()}`;
}

export function displayStaticDatum(
  value: string | number | null | undefined,
  numberDisplayOption?: {
    numDecimalPoints?: number;
    percent?: boolean;
    shouldShowPlainZero?: boolean;
  },
): string {
  if (value == null) return '-';
  if (typeof value === 'number') return numDecimalPoints(value, numberDisplayOption);
  return value.toString();
}
