import { StrategyTrackResponseDTO } from './types/StrategyTrackResponseDTO';
import { StrategyPriceResponseDTO } from './types/StrategyPriceResponseDTO';
import request, { checkBlockPopup } from '@/api-v2/request';
import { PeriodAbbrevEnum } from '@/constants/PeriodAbbrevEnum';
import { ReturnInterval } from '@/constants/ReturnInterval';
import { IFrontierLineQuery, IFrontierLineResponse } from '@/types/query/IFrontierLineQuery';
import { FileSignedUrlDTO } from '@/types/dto/FileSignedUrlDTO';
import { FileService } from '@/api-v2/web/file';
import { IRollingTrackResponse } from '@/types/IRollingTrackResponse';
import { IBenchmarkCorrelationQuery } from '@/types/IBenchmarkCorrelationQuery';
import { IBenchmarkCorrelationResponseDatum } from '@/types/IBenchmarkCorrelationResponseDatum';
import { IPureFactorResponse } from './types/IPureFactorResponse';
import { IRollingTrackQuery } from '@/types/IRollingTrackQuery';
import { ITrackQuery } from '@/types/ITrackQuery';
import { RouteName } from '@/constants/RouteName';
import { CurrencyConversion } from '@/types/CurrencyConversion';
import { useDefaultPdfOptions } from '@/composables/usePdf';
import { UploadTrackRequestDTO, UploadTrackResponseDTO } from './types/UploadTrackDTO';
import { StrategyFactsheetResponseDTO } from './types/StrategyFactsheetResponseDTO';
import { StrategyAttributesDTO } from './types/StrategyAttributesDTO';
import { StrategyProviderRequestDTO, StrategyProviderResponseDTO } from './types/StrategyProvider';
import { StrategyIdentifiersRequestDTO, StrategyIdentifiersResponseDTO } from './types/StrategyIdentifiers';
import { StrategyAttachmentResponseDTO } from './types/StrategyAttachment';
import { ReturnRegressionStrategyRequestDTO } from '../active-return/types/ReturnRegressionStrategyRequestDTO';
import { IReturnRegressionResponse } from '@/types/IReturnRegressionResponse';
import { StrategyAttachmentType } from '@/types/StrategyAttachment';
import { GetTracksDTO } from './types/GetTracksDTO';
import { FxConvertedTrackMap } from './types/GetTracksResponseDTO';
import { IKdeResponseDto } from '@/types/IKdeResponseDto';
import { IStrategyKdeQuery } from '@/types/IStrategyKdeQuery';
import { TrackValueTypeConstants } from '@/constants/TrackValueTypeConstants';

export const getStrategyProviders = (params: StrategyProviderRequestDTO): Promise<StrategyProviderResponseDTO[]> =>
  request({
    url: `/strategies/providers`,
    method: 'get',
    params,
  }).then((o): StrategyProviderResponseDTO[] => o.data);

export const getStrategyIdentifiers = (
  params: StrategyIdentifiersRequestDTO,
): Promise<StrategyIdentifiersResponseDTO[]> =>
  request({
    url: `/strategies/identifiers`,
    method: 'get',
    params,
  }).then((o): StrategyIdentifiersResponseDTO[] => o.data);

export const getStrategyFactsheet = (code: string): Promise<StrategyFactsheetResponseDTO> =>
  request({
    url: `/strategies/${code}/factsheet`,
    method: 'get',
  }).then((o): StrategyFactsheetResponseDTO => o.data);

export const setStrategyAttributes = (code: string, data: StrategyAttributesDTO): Promise<void> =>
  request({
    url: `/strategies/${code}/attributes`,
    method: 'post',
    data,
  }).then((o) => o.data);

export const getStrategyTrack = (code: string, query?: ITrackQuery): Promise<StrategyTrackResponseDTO> =>
  request({
    url: `/strategies/${code}/track`,
    method: 'get',
    params: query,
  }).then((o): StrategyTrackResponseDTO => o.data);

/**
 * Function to get the price or total return track for a strategy code
 * @param code strategy code to get the price for
 * @param valueType TrackValueTypeConstants to get the price or total return
 * @returns price data for the strategy
 */
export const getStrategyPrice = (
  code: string,
  valueType?: TrackValueTypeConstants,
): Promise<StrategyPriceResponseDTO[]> =>
  request({
    url: `/strategies/${code}/price`,
    method: 'get',
    params: { valueType },
  }).then((o) => o.data);

export const getPureFactorSnapshotTable = (date: string): Promise<IPureFactorResponse[] | undefined> =>
  request({
    url: `/strategies/research`,
    method: 'get',
    params: {
      date,
    },
  }).then((o) => o.data);

/**
 *
 * @param code code of the desired strategy
 * @param query
 */
export const getStrategyRollingTrack = (code: string, query: IRollingTrackQuery): Promise<IRollingTrackResponse> =>
  request({
    url: `/strategies/${code}/rolling-track`,
    method: 'get',
    params: query,
  }).then((o) => o.data);

export const getStrategyReturnRegression = (
  code: string,
  payload: ReturnRegressionStrategyRequestDTO,
): Promise<IReturnRegressionResponse | undefined> =>
  request({
    url: `/strategies/${code}/regression`,
    method: 'post',
    data: payload,
  }).then((o) => o.data);

/**
 *
 * @param code code of the desired strategy
 * @param query
 */
export const getStrategyBenchmarkCorrelation = (params: {
  code: string;
  query: IBenchmarkCorrelationQuery;
}): Promise<IBenchmarkCorrelationResponseDatum[] | undefined> =>
  request({
    url: `/strategies/${params.code}/correlation`,
    method: 'get',
    params: params.query,
  }).then((o) => o.data);

const defaultPdfOptions = useDefaultPdfOptions();

export const getStrategyPdf = async (
  code: string,
  url: string,
  params: {
    returnInterval: ReturnInterval;
    period: PeriodAbbrevEnum;
    startDate: string;
    endDate: string;
  },
  indexName: string,
): Promise<void> => {
  const dto = await request({
    url: `/strategies/${code}/pdf`,
    method: 'post',
    data: {
      url,
      params,
      options: defaultPdfOptions,
    },
  }).then((o): FileSignedUrlDTO => o.data);

  if (!dto.signedURL) return;
  checkBlockPopup();

  await FileService.downloadWithCloudFrontUrl(dto.signedURL, 'pdf', {
    tool: RouteName.STRATEGY_FACTSHEET,
    indexName,
    date: params.endDate,
  });
};

export const getFrontierLine = (query: IFrontierLineQuery): Promise<IFrontierLineResponse | undefined> =>
  request({
    url: `/strategies/frontier`,
    method: 'POST',
    data: query,
  }).then((res) => res.data);

export const getStrategyExcel = async (
  code: string,
  query: {
    endDate: string;
    startDate: string;
    returnInterval: ReturnInterval;
    regimeId?: string;
  } & CurrencyConversion,
  indexName: string,
): Promise<void> => {
  const strategyExcelDto = await request({
    url: `/strategies/${code}/excel`,
    method: 'POST',
    params: query,
  })
    .then((o) => o.data as FileSignedUrlDTO)
    .catch((err) => {
      throw err;
    });
  if (!strategyExcelDto.signedURL) return;
  checkBlockPopup();

  await FileService.downloadWithCloudFrontUrl(strategyExcelDto.signedURL, 'xlsx', {
    tool: RouteName.STRATEGY_FACTSHEET,
    indexName,
  });
};

export const uploadStrategy = (data: UploadTrackRequestDTO): Promise<UploadTrackResponseDTO> =>
  request({
    url: `/strategies/upload`,
    method: 'POST',
    data,
  })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });

export const getStrategyAttachment = (
  code: string,
  signal?: AbortSignal,
  type?: StrategyAttachmentType,
): Promise<StrategyAttachmentResponseDTO | null> =>
  request({
    url: `/strategies/${code}/attachment/${type}`,
    method: 'GET',
    signal,
  }).then((res) => res.data);

export const getLatestIndexPrice = (slug: string): Promise<{ date: string; price: number }> =>
  request({
    url: `/strategies/${slug}/latestTrack`,
    method: 'GET',
  })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });

export const getFxConvertedPriceByDate = (
  payload: GetTracksDTO,
  strategyCodes: string,
): Promise<FxConvertedTrackMap[]> =>
  request({
    url: '/strategies/tracks',
    method: 'POST',
    params: { strategyCodes: strategyCodes },
    data: payload,
  }).then((res) => res.data);

export const getStrategyKDE = (code: string, query?: IStrategyKdeQuery): Promise<IKdeResponseDto> =>
  request({
    url: `/strategies/${code}/kde`,
    method: 'get',
    params: query,
  }).then((o): IKdeResponseDto => o.data);
