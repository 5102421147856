import { IConstituentRiskTreeMap } from '@/types/IConstituentRiskTreeMap';
import { IConstituentRiskCommodityForward } from '@/types/IConstituentRiskCommodityForward';
import { IConstituentRiskVarComponent } from '@/types/IConstituentRiskVarComponent';
import { IConstituentRiskStressTest } from '@/types/IConstituentRiskStressTest';
import { IConstituentRiskCreditHeatMap, IConstituentRiskHeatMap } from '@/types/IConstituentRiskHeatMap';
import { IConstituentRiskTable } from '@/types/IConstituentRiskTable';
import { IConstituentRiskMetricTrack } from '@/types/IConstituentRiskMetricTrack';
import { IConstituentRiskSpotLadder } from '@/types/IConstituentRiskSpotLadder';
import {
  CommodityFilterConstants,
  FilterConstants,
  EquityGroupByConstants,
  StressTestDropdownType,
  TableLayerConstants,
  UnitConstants,
  HistoryGroupBy,
} from './analytics/ConstituentRisk';
import { MetricConstants } from '@/constants/MetricConstants';
import { Currency } from '@/constants/Currency';

export type IConstituentRisk = {
  Dates: string[] | null;
  RiskTable: IConstituentRiskTable | null;
  CommodityTreeMap: IConstituentRiskTreeMap[] | null;
  CommodityForward: IConstituentRiskCommodityForward | null;
  CommoditySpotLadder: IConstituentRiskSpotLadder | null;
  CommodityTableDelta: IConstituentRiskTable | null;
  CommodityTableGamma: IConstituentRiskTable | null;
  CommodityMetricTrack: IConstituentRiskMetricTrack | null;
  CommodityLadderTable: IConstituentRiskTable | null;
  EquityTreeMap: IConstituentRiskTreeMap[] | null;
  EquityBarChart: IConstituentRiskTreeMap[] | null;
  EquitySpotLadder: IConstituentRiskSpotLadder | null;
  EquityMetricTrack: IConstituentRiskMetricTrack | null;
  EquityLadderTable: IConstituentRiskTable | null;
  VarCharts: IConstituentRiskVarComponent | null;
  VarMetricTrack: IConstituentRiskMetricTrack | null;
  ExposureMetricTrack: IConstituentRiskMetricTrack | null;
  StressTest: IConstituentRiskStressTest[] | null;
  StressTestMetricTrack: IConstituentRiskMetricTrack | null;
  FXChart: IConstituentRiskStressTest[] | null;
  FXMetricTrack: IConstituentRiskMetricTrack | null;
  FXSpotLadder: IConstituentRiskSpotLadder | null;
  FXTable: IConstituentRiskTable | null;
  FXLadderTable: IConstituentRiskTable | null;
  IRChart: IConstituentRiskHeatMap[] | null;
  IRMetricTrack: IConstituentRiskMetricTrack | null;
  IRSpotLadder: IConstituentRiskSpotLadder | null;
  IRTable: IConstituentRiskTable | null;
  IRNormalLadderTable: IConstituentRiskTable | null;
  IRBsLadderTable: IConstituentRiskTable | null;
  CreditChart: IConstituentRiskCreditHeatMap[] | null;
  CreditMetricTrack: IConstituentRiskMetricTrack | null;
  CreditTable: IConstituentRiskTable | null;
};

/**
 * @param slug Portfolio slug name
 * @param code Strategy code
 * @param analysisCodes Codes to fetch
 * @param component Array of data components to receive
 * @param portfolioTreeId treeId of select subportfolio
 * @param date Portfolio position date
 * @param rollingDate Reference date, used for the commodity tree map rolling values
 * @param unit Can either be % or $
 * @param equityMetric Metric to use in the calculation (Delta, Gamma, Vega)
 * @param equityFilterLevel1 First filter level
 * @param equityFilterLevel2 Second filter level
 * @param commodityMetric Metric to use in the calculation (Delta, Gamma, Vega)
 * @param varMetric1 Var item to fetch
 * @param varMetric1GroupBy Var group by for allocated
 * @param varMetric2 Exposure item to fetch
 * @param stressTestOptions Dictionary of stress tests and their shocks
 * @param stressTestHistoryOption Stress test to use for history mode
 * @param stressTestFilter Filter
 * @param fxFilter Filter
 * @param trackSegment Time Chart should be calculated at the portfolio or strategy level
 * @param basisPoints Basis Point to use for the ribbon and charts of Credit and Interest Rate
 * @param filterNullQuantityPosition Boolean to filter out positions with null or 0 values

 */
export type IConstituentRiskQuery = {
  component: RiskDashboardComponentsConstants[];
  portfolioTreeId?: string;
  /**
   * Replaces `suspendedForRisk` in the portfolioTreeDraft. Query will only be run using codes in this property
   */
  analysisCodes: string[];
  date: string;
  rollingDate: string | undefined;
  unit: UnitConstants;
  equityMetric: MetricConstants;
  equityFilterLevel1: EquityGroupByConstants | FilterConstants;
  equityFilterLevel2: EquityGroupByConstants | FilterConstants;
  commodityMetric: MetricConstants;
  commodityFilter: CommodityFilterConstants;
  varMetric1: string;
  varMetric1GroupBy: FilterConstants;
  varMetric2: string;
  stressTestOptions: StressTestDropdownType[];
  stressTestHistoryOption: StressTestDropdownType;
  stressTestFilter: FilterConstants;
  fxFilter: FilterConstants;
  trackSegment: FilterConstants | HistoryGroupBy;
  basisPoints: number;
  filterNullQuantityPosition: boolean;
  reportingCurrency?: Currency;
} & ({ code: string } | { slug: string });

export enum RiskDashboardComponentsConstants {
  VarCharts = 'VarCharts',
  StressTest = 'StressTest',
  EquityTreeMap = 'EquityTreeMap',
  EquityBarChart = 'EquityBarChart',
  EquitySpotLadder = 'EquitySpotLadder',
  IRChart = 'IRChart',
  IRSpotLadder = 'IRSpotLadder',
  CreditChart = 'CreditChart',
  FXChart = 'FXChart',
  FXSpotLadder = 'FXSpotLadder',
  CommodityTreeMap = 'CommodityTreeMap',
  CommodityForward = 'CommodityForward',
  CommoditySpotLadder = 'CommoditySpotLadder',
  VarMetricTrack = 'VarMetricTrack',
  ExposureMetricTrack = 'ExposureMetricTrack',
  StressTestMetricTrack = 'StressTestMetricTrack',
  EquityMetricTrack = 'EquityMetricTrack',
  IRMetricTrack = 'IRMetricTrack',
  CreditMetricTrack = 'CreditMetricTrack',
  FXMetricTrack = 'FXMetricTrack',
  CommodityMetricTrack = 'CommodityMetricTrack',
}

/**
 * @param slug Portfolio slug name
 * @param component Table component to receive
 * @param analysisCodes Codes to fetch
 * @param portfolioTreeId treeId of select subportfolio
 * @param date Portfolio position date
 * @param fields Portfolio fields chosen by options
 * @param unit Can either be % or $
 * @param equityMetric Metric to use in the calculation (Delta, Gamma, Vega)
 * @param commodityMetric Metric to use in the calculation (Delta, Gamma, Vega)
 * @param basisPoints Basis Point to use for the ribbon and charts of Credit and Interest Rate
 * @param riskTableLayers List of table layers
 * @param filterNullQuantityPosition Boolean to filter out positions with null or 0 values
 */
export type IConstituentRiskTableQuery = {
  component: RiskDashboardTableComponentsConstants;
  analysisCodes: string[];
  portfolioTreeId?: string;
  fields?: string[] | undefined;
  date: string;
  unit: UnitConstants;
  equityMetric: MetricConstants;
  commodityMetric: MetricConstants;
  basisPoints: number;
  riskTableLayers: TableLayerConstants[];
  filterNullQuantityPosition: boolean;
  reportingCurrency?: Currency;
} & ({ code: string } | { slug: string });

export enum RiskDashboardTableComponentsConstants {
  RiskTable = 'RiskTable',
  CommodityTableDelta = 'CommodityTableDelta',
  CommodityTableGamma = 'CommodityTableGamma',
  CommodityLadderTable = 'CommodityLadderTable',
  EquityLadderTable = 'EquityLadderTable',
  FXTable = 'FXTable',
  FXLadderTable = 'FXLadderTable',
  IRTable = 'IRTable',
  IRNormalLadderTable = 'IRNormalLadderTable',
  IRBsLadderTable = 'IRBsLadderTable',
  CreditTable = 'CreditTable',
}

/**
 * @param slug Portfolio slug name
 * @param code Strategy code
 * @param analysisCodes Codes to fetch
 * @param date Portfolio position date
 * @param rollingDate Reference date, used for the ribbon rolling values
 * @param unit Can either be % or $
 * @param ribbonFieldNames List of ribbon metrics to fetch
 * @param basisPoints Basis Point to use for the ribbon and charts of Credit and Interest Rate
 * @param filterNullQuantityPosition Boolean to filter out positions with null or 0 values
 */
export type IConstituentRibbonQuery = {
  analysisCodes: string[];
  date: string;
  rollingDate?: string;
  unit?: UnitConstants;
  ribbonFieldNames?: string[];
  basisPoints?: number;
  filterNullQuantityPosition?: boolean;
  reportingCurrency?: Currency;
} & ({ code: string } | { slug: string });
